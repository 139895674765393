<template>
  <AppDialog
    v-model="isDialogVisible"
    header="Assign submission(s) to group"
    @click:close="emit('close')"
  >
    <div class="flex flex-col gap-3">
      <h3 class="caption-subheading">Select group</h3>
      <ul class="flex flex-col">
        <li
          v-for="group in submissionGroups"
          :key="group.id"
          class="py-3 flex justify-start gap-2 items-center px-2 border-b last-of-type:border-b-0 duration-200 cursor-pointer bg-white hover:bg-surface-50"
          @click="handleGroupSelection(group.id)"
        >
          <AppTag :color="group.color">{{ group.name }}</AppTag>
        </li>
      </ul>
    </div>
    <template #actions>
      <AppButtonSecondary
        label="Cancel"
        @click="$emit('resolve', false)"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
import type { SubmissionGroup } from '~/types/SubmissionGroup'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  submissionGroups: {
    type: Array as PropType<SubmissionGroup[]>,
    required: true,
  },
})

const isDialogVisible = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

function handleGroupSelection(groupId: string) {
  emit('resolve', groupId)
}
</script>
