// 4 sources of candidate data
// 1) User store (preview mode - authed user)
// 2) Manual candidate data (dialog entry - `?manual_candidate=true`)
// 3) Query params (`?organization=...&identifier=...&firstName=...&lastName=...`)
// 4) Iframe or post message
// 5) Demo iframe (uses static data - `?demo=true`)

import * as Sentry from '@sentry/vue'
import { useRouteQuery } from '@vueuse/router'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'
import type { Candidate } from '~/types/Candidate'
import type { Unsaved } from '~/types/Unsaved'

export const useCandidateStore = defineStore('candidate', () => {
  // OTHER SERVICES =================================================================================
  const { showDialog } = useDialogService()
  const CandidateService = candidateService()

  // STORES =========================================================================================
  const userStore = useUserStore()

  // GLOBAL STATE ===================================================================================
  const candidate = ref<Candidate | null>(null)

  // ROUTE QUERY PARAMS =============================================================================
  const queryIdentifier = useRouteQuery('identifier')
  const queryOrganization = useRouteQuery('organization')
  const queryFirstName = useRouteQuery('firstName')
  const queryLastName = useRouteQuery('lastName')
  const queryDemo = useRouteQuery('demo')
  const queryManualCandidate = useRouteQuery('manual_candidate')

  async function getFullCandidateData(candidateData: Unsaved<Candidate>) {
    const { data, error } =
      await CandidateService.upsertCandidate(candidateData)

    if (error !== null) {
      console.error('Error getting full candidate data:', error)
      return null
    }

    return data
  }

  // 5) IFRAME DEMO =================================================================================
  const useIframeDemo = computed(
    () => queryDemo.value !== null && queryDemo.value === 'true',
  )

  // 3) QUERY PARAMS & 5) IFRAME DEMO ===============================================================
  const candidateInQueryParams = computed<Unsaved<Candidate> | null>(() => {
    // !TEMP FOR DEMO
    if (useIframeDemo.value) {
      return {
        identifier: 'iframe',
        organization: 'demo',
        firstName: 'Nic',
        lastName: 'Pullen',
      }
    }

    // Check for query keys
    if (
      !queryIdentifier.value ||
      !queryOrganization.value ||
      !queryFirstName.value ||
      !queryLastName.value ||
      typeof queryIdentifier.value !== 'string' ||
      typeof queryOrganization.value !== 'string' ||
      typeof queryFirstName.value !== 'string' ||
      typeof queryLastName.value !== 'string'
    ) {
      return null
    }

    return {
      identifier: queryIdentifier.value,
      organization: queryOrganization.value,
      firstName: queryFirstName.value,
      lastName: queryLastName.value,
    }
  })

  // 2) MANUAL CANDIDATE DATA =======================================================================
  const useManualCandidateData = computed(
    () => queryManualCandidate.value !== undefined,
  )

  async function getManualCandidateData() {
    const candidateDataInLocalStorage = localStorage.getItem('candidateData')

    if (candidateDataInLocalStorage) {
      const parsedCandidateData = JSON.parse(candidateDataInLocalStorage)

      if (
        parsedCandidateData &&
        queryOrganization.value &&
        typeof queryOrganization.value === 'string'
      ) {
        parsedCandidateData.organization = queryOrganization.value
      }

      return parsedCandidateData
    }

    const candidateData = await showDialog<Unsaved<Candidate>>({
      type: 'manual-candidate-data',
    })

    if (
      candidateData &&
      queryOrganization.value &&
      typeof queryOrganization.value === 'string'
    ) {
      candidateData.organization = queryOrganization.value
    }

    localStorage.setItem('candidateData', JSON.stringify(candidateData))

    return candidateData
  }

  // 4) POST MESSAGE =================================================================================

  async function loadCandidateData() {
    candidate.value = null

    // 1) USER STORE (PREVIEW MODE)
    await userStore.$ensureInitialised()

    if (userStore.userCandidateData !== null) {
      candidate.value = await getFullCandidateData(userStore.userCandidateData)
      return
    }

    // 2) MANUAL CANDIDATE DATA
    if (useManualCandidateData.value) {
      const candidateDataFromDialog = await getManualCandidateData()

      if (!candidateDataFromDialog) return

      candidate.value = await getFullCandidateData(candidateDataFromDialog)
      return
    }

    // 3) QUERY PARAMS
    if (candidateInQueryParams.value !== null) {
      candidate.value = await getFullCandidateData(candidateInQueryParams.value)
      // return
    }

    // 4) IFRAME OR POST MESSAGE
    // ! Temporarily removed - hopefully not needed
    // const { stop: stopPolling } = usePoll(refreshInteraction, 1000)

    // postIframeMessage(interaction.value.simulationId, interaction.value.id)

    // await waitUntil(() => isInteractionReady.value, {
    //   timeoutMs: 10000,
    // })

    // stopPolling()

    // if (!interaction.value.candidateId) {
    //   errorMessage.value = 'Could not load candidate data'
    //   hasError.value = true
    //   isLoading.value = false
    //   return
    // }

    // POPULATE LOCAL CANDIDATE DATA
    // const { data, error } = await InteractionService.getCandidateById(
    //   interaction.value.simulationId,
    //   interaction.value.id,
    //   interaction.value.candidateId,
    // )

    // if (error !== null) {
    //   errorMessage.value = 'Could not load candidate data'
    //   hasError.value = true
    //   isLoading.value = false
    //   return
    // }

    // candidate.value = data
  }

  // function postIframeMessage(simulationId: string, interactionId: string) {
  //   top?.postMessage({
  //     type: 'MIMICLY_CANDIDATE_DATA_REQUEST',
  //     simulationId,
  //     interactionId,
  //   })
  // }

  watch(
    () => candidate.value,
    (newCandidateData) => {
      if (userStore.user !== null) return

      Sentry.setUser({
        id: `candidate:${newCandidateData?.organization || '[no organization]'}::${newCandidateData?.identifier || '[no identifier]'}`,
      })
    },
  )

  function $reset() {
    candidate.value = null
  }

  return {
    candidate,
    loadCandidateData,
    $reset,
  }
})
