import { type Artefact } from '~/types/Artefact'

function getFormDataFromFile(file: File) {
  const formData = new FormData()
  formData.append('file', file, file.name)
  return formData
}

function getFormDataFromFiles(files: File[] | FileList) {
  const formData = new FormData()
  Array.from(files).forEach((file) => {
    formData.append('files', file, file.name)
  })
  return formData
}

export function fileUploadService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const { postResource } = httpService(serverUrl)

  async function uploadFile(file: File) {
    const response = await postResource<Artefact, FormData>(
      `/uploads`,
      getFormDataFromFile(file),
    )
    return response
  }

  async function uploadCharacterAvatar(characterId: string, file: File) {
    const response = await postResource(
      `/characters/${characterId}/upload-avatar`,
      getFormDataFromFile(file),
    )
    return response
  }

  async function uploadSimulationAgendaArtefact(
    simulationId: string,
    agendaId: string,
    file: File,
  ) {
    const response = await postResource(
      `/simulations/${simulationId}/agenda/${agendaId}/upload-artefact`,
      getFormDataFromFile(file),
    )
    return response
  }

  async function uploadSimulationOutcomeArtefact(
    simulationId: string,
    outcomeId: string,
    file: File,
  ) {
    const response = await postResource(
      `/simulations/${simulationId}/agenda/${outcomeId}/upload-artefact`,
      getFormDataFromFile(file),
    )
    return response
  }

  async function uploadSimulationEmailReceivedAttachment(
    simulationId: string,
    file: File,
  ) {
    const response = await postResource(
      `/simulations/${simulationId}/upload-email-received-attachment-artefact`,
      getFormDataFromFile(file),
    )
    return response
  }

  async function uploadEmailCandidateFile(emailId: string, file: File) {
    const response = await postResource(
      `/emails/${emailId}/upload-artefact`,
      getFormDataFromFile(file),
    )
    return response
  }

  async function uploadChatCandidateFile(chatId: string, file: File) {
    const response = await postResource(
      `/chats/${chatId}/upload-artefact`,
      getFormDataFromFile(file),
    )
    return response
  }

  async function uploadFileToEndpoint<ResponseT>(endpoint: string, file: File) {
    const response = await postResource<ResponseT, FormData>(
      endpoint,
      getFormDataFromFile(file),
    )
    return response
  }

  async function uploadFilesToEndpoint<ResponseT>(
    endpoint: string,
    files: File[] | FileList,
    additionalFormData?: Record<string, string>,
  ) {
    const formData = getFormDataFromFiles(files)

    if (additionalFormData) {
      Object.entries(additionalFormData).forEach(([key, value]) => {
        formData.append(key, value)
      })
    }

    const response = await postResource<ResponseT, FormData>(
      endpoint,
      formData,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  return {
    uploadFile,
    uploadCharacterAvatar,
    uploadSimulationAgendaArtefact,
    uploadSimulationOutcomeArtefact,
    uploadSimulationEmailReceivedAttachment,
    uploadEmailCandidateFile,
    uploadChatCandidateFile,
    uploadFileToEndpoint,
    uploadFilesToEndpoint,
  }
}
