import type { GradingStatus } from './TestAttempt'

export interface BaseSseMessage {
  eventType: string
}

export enum SseMessageType {
  // CONNECTION =================================================
  CONNECTION_ESTABLISHED = 'connection-established',
  CONNECTION_CLOSED = 'connection-closed',

  // STATE UPDATES ==============================================
  // Test Attempt Grade
  TEST_ATTEMPT_GRADE_UPDATE = 'test-attempt-grade-update',
  TEST_ATTEMPT_GRADE_STATUS_UPDATE = 'test-attempt-grade-status-update',

  // Assessment Attempt Grade
  ASSESSMENT_ATTEMPT_GRADE_STATUS_UPDATE = 'assessment-attempt-grade-status-update',

  // FUTURE =========================
  // Test Attempt
  TEST_ATTEMPT_UPDATE = 'test-attempt-update',
  TEST_ATTEMPT_CREATE = 'test-attempt-create',
  TEST_ATTEMPT_DELETE = 'test-attempt-delete',

  // Test Grade Ranking
  TEST_GRADE_RANKING_INVALIDATION_UPDATE = 'test-grade-ranking-invalidation-update',
  TEST_GRADE_RANKING_UPDATE = 'test-grade-ranking-update',

  // Assessment Grade Rankings
  ASSESSMENT_GRADE_RANKING_INVALIDATION_UPDATE = 'assessment-grade-ranking-invalidation-update',
  ASSESSMENT_GRADE_RANKING_UPDATE = 'assessment-grade-ranking-update',

  // Test Score Distributions
  TEST_SCORE_DISTRIBUTION_UPDATE = 'test-score-distribution-update',

  // Assessment Score Distributions
  ASSESSMENT_SCORE_DISTRIBUTION_UPDATE = 'assessment-score-distribution-update',

  // Test Lock Update
  TEST_LOCK_UPDATE = 'test-lock-update',

  // Assessment Lock Update
  ASSESSMENT_LOCK_UPDATE = 'assessment-lock-update',
}

export interface BaseStateUpdateMessage extends BaseSseMessage {
  eventType: SseMessageType
  id: string
}

export interface TestAttemptGradeUpdateMessage extends BaseStateUpdateMessage {
  eventType: SseMessageType.TEST_ATTEMPT_GRADE_UPDATE
  id: string // testAttemptGrade.id
  data: {
    testId: string
  }
}

export interface TestAttemptGradeStatusUpdateMessage
  extends BaseStateUpdateMessage {
  eventType: SseMessageType.TEST_ATTEMPT_GRADE_STATUS_UPDATE
  id: string // testAttemptGrade.id
  data: {
    testId: string
    status: GradingStatus
  }
}

export interface AssessmentAttemptGradeUpdateMessage
  extends BaseStateUpdateMessage {
  eventType: SseMessageType.ASSESSMENT_ATTEMPT_GRADE_STATUS_UPDATE
  id: string // assessmentAttemptGrade.id
  data: {
    assessmentId: string
    status: GradingStatus
  }
}

export interface TestGradeRankingInvalidationUpdateMessage
  extends BaseStateUpdateMessage {
  eventType: SseMessageType.TEST_GRADE_RANKING_INVALIDATION_UPDATE
  id: string // testGradeRanking.id
  data: {
    testId: string
    isValid: boolean
  }
}

export interface TestGradeRankingUpdateMessage extends BaseStateUpdateMessage {
  eventType: SseMessageType.TEST_GRADE_RANKING_UPDATE
  id: string // testGradeRanking.id
  data: {
    testId: string
  }
}

export interface AssessmentGradeRankingInvalidationUpdateMessage
  extends BaseStateUpdateMessage {
  eventType: SseMessageType.ASSESSMENT_GRADE_RANKING_INVALIDATION_UPDATE
  id: string // assessmentGradeRanking.id
  data: {
    assessmentId: string
    isValid: boolean
  }
}

export interface AssessmentGradeRankingUpdateMessage
  extends BaseStateUpdateMessage {
  eventType: SseMessageType.ASSESSMENT_GRADE_RANKING_UPDATE
  id: string // assessmentGradeRanking.id
  data: {
    assessmentId: string
  }
}

export interface TestScoreDistributionUpdateMessage
  extends BaseStateUpdateMessage {
  eventType: SseMessageType.TEST_SCORE_DISTRIBUTION_UPDATE
  id: string // testScoreDistribution.id
  data: {
    testId: string
    lowerBound: number
    upperBound: number
    count: number
  }
}

export interface AssessmentScoreDistributionUpdateMessage
  extends BaseStateUpdateMessage {
  eventType: SseMessageType.ASSESSMENT_SCORE_DISTRIBUTION_UPDATE
  id: string // assessmentScoreDistribution.id
  data: {
    assessmentId: string
    lowerBound: number
    upperBound: number
    count: number
  }
}

export interface TestLockUpdateMessage extends BaseStateUpdateMessage {
  eventType: SseMessageType.TEST_LOCK_UPDATE
  id: string // test.id
  data: {
    lockedBy: string | null
    lockedAt: string | null
  }
}

export interface AssessmentLockUpdateMessage extends BaseStateUpdateMessage {
  eventType: SseMessageType.ASSESSMENT_LOCK_UPDATE
  id: string // assessment.id
  data: {
    lockedBy: string | null
    lockedAt: string | null
  }
}
