import { useWindowFocus } from '@vueuse/core'

export function usePoll(
  fn: () => Promise<any>,
  interval: MaybeRefOrGetter<number>,
  startAutomatically = true,
  pauseOnWindowBlur = true,
) {
  const { resume, pause } = useTimeoutPoll(fn, interval, { immediate: false })

  function start() {
    const delay = unref(interval) as number
    setTimeout(() => resume(), delay)
  }

  function stop() {
    pause()
  }

  if (startAutomatically) {
    start()
  }

  if (pauseOnWindowBlur) {
    const isWindowFocused = useWindowFocus()
    watch(
      () => isWindowFocused.value,
      (focused) => {
        if (!focused) stop()
        else start()
      },
    )
  }

  return { start, stop }
}
