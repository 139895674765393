import { defineStore } from 'pinia'
import { useAuth0 } from '@auth0/auth0-vue'
import * as Sentry from '@sentry/vue'

export const useAuthStore = defineStore('auth', () => {
  const auth0 = useAuth0()

  const $isInitialising = computed(() => auth0.isLoading.value)

  const isAuthenticated = computed(() => auth0.isAuthenticated.value)

  const authUser = computed(() => auth0.user.value)

  const authServiceId = computed(() => authUser.value?.sub)

  async function signIn(returnTo: string = '/') {
    await auth0.loginWithRedirect({
      appState: {
        target: returnTo,
      },
    })
  }

  async function signOut() {
    await auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  async function getToken() {
    try {
      const response = await auth0.getAccessTokenSilently()
      return response
    } catch (error: any) {
      // Missing Refresh Token for 'mimicly-api-stg'
      if (
        error.error === 'missing_refresh_token' ||
        error.error === 'invalid_grant' ||
        error.error === 'login_required'
      ) {
        await signIn()
        return
      }

      Sentry.captureException(error)

      showError({
        message:
          error.error_description ||
          'Unknown error occured while getting access token',
        statusCode: error.statusCode || 500,
        statusMessage:
          error.error_description ||
          'Unknown error occured while getting access token',
      })
    }
  }

  const $initialise = useGlobalPromise(async () => {
    await auth0.checkSession()

    if (!isAuthenticated.value) return

    try {
      await auth0.getAccessTokenSilently()
    } catch (error: any) {
      // Missing Refresh Token for 'mimicly-api-stg'
      if (
        error.error === 'missing_refresh_token' ||
        error.error === 'invalid_grant'
      ) {
        await signIn()
        return
      }

      Sentry.captureException(error)

      showError({
        message:
          error.error_description ||
          'Unknown error occured while getting access token',
        statusCode: error.statusCode || 500,
        statusMessage:
          error.error_description ||
          'Unknown error occured while getting access token',
      })
    }
  })

  $initialise()

  return {
    $isInitialising,
    isAuthenticated,
    authUser,
    authServiceId,
    getToken,
    signIn,
    signOut,
    $initialise,
  }
})
