import { AuthoringStatus } from '~/types/AuthoringStatus'
import { type Unsaved, type WithoutMetaTimes } from '~/types/Unsaved'
import {
  type Test,
  type TestGradingCategory,
  type TestGradingCategoryLevel,
  type Question,
  TestFormat,
  type Choice,
  type WrittenQuestionGradingCategory,
  type WrittenQuestionGradingCategoryLevel,
  CategoryScoreAllocation,
  CategoryScoreWeighting,
  type GradingBlock,
} from '~/types/Test'
import { ColorOption } from '~/types/Colors'

export function getTestDefault(
  testFormat: TestFormat = TestFormat.Mcq,
): Required<Unsaved<Test>> {
  return {
    authoringStatus: AuthoringStatus.Draft,
    format: testFormat,
    testType: null,
    title: '',
    isPublished: false,
    heading: '',
    requiresBlurReporting: false,
    useTimeLimit: false,
    durationMinutes: null,
    forceFullScreen: false,
    introduction: '',
    objective: '',
    questions: [],
    categoryScoreAllocation: CategoryScoreAllocation.ActualUserScore,
    categoryScoreWeighting: CategoryScoreWeighting.Points,
    rubric: [],
    gradingType: null,
    maxAvailablePoints: 0,
    shouldRandomiseQuestionOrder: false,

    // MCQ ------------------------------
    shouldRandomiseResponseOptions: false,

    // MCQ & Written ------------------------------
    hasConfidenceRating: false,

    // Written ------------------------------
    uploadedFile: null,

    // Defaults ------------------------------
    choicesDefault: null,
    confidenceRatingsDefault: {
      5: [-1, 1],
      4: [-1, 1],
      3: [-1, 1],
      2: [-1, 1],
      1: [-1, 1],
    },

    primaryWorkspaceId: '',
    workspaces: [],
    isGlobal: false,

    gradingBlocks: [],

    originalContextFiles: [],
    markdownContextFiles: [],
    contextFileContext: '',
    gradingConfidenceRatings: {
      veryConfident: 90,
      confident: 70,
    },
    submissionContext: '',
    lockedAt: null,
    lockedBy: null,

    autoGrade: testFormat !== TestFormat.FileSubmission, // FS test default to false, else true

    createdBy: '',
  }
}

export const gradingCategoryLevelDefault: Required<TestGradingCategoryLevel> = {
  id: '',
  authoringStatus: AuthoringStatus.Draft,
  level: null,
  requiredPercentage: null,
  title: '',
  description: '',
  gradingStatements: [],
  requiredPoints: null,
}

export function getGradingCategoryDefault(): Required<TestGradingCategory> {
  return {
    id: getNewId(),
    authoringStatus: AuthoringStatus.Draft,
    title: '',
    levels: [
      {
        ...gradingCategoryLevelDefault,
        level: 0,
        requiredPercentage: 0,
        title: 'Not Achieved',
        description: 'Not achieved',
        requiredPoints: 0,
        id: getNewId(),
      },
    ],
    weighting: 0,
    displayColor: ColorOption.Primary,
    maxAvailablePoints: 0,
    description: '',
    instructions: '',
    displayIndex: 0,
  }
}

export const getQuestionDefault = (): Required<Question> => ({
  id: getNewId(),
  authoringStatus: AuthoringStatus.Draft,
  displayIndex: -1,
  required: true,
  text: '',
  heading: null,
  imageUrl: null,
  externalContentUrl: null,
  externalContentType: null,
  simulationId: null,
  requiresGradingNote: false,
  gradingNoteInstructions: '',
  rubric: [],
  gradingContext: {
    type: null,
    text: '',
  },
  testGradingCategoryId: null,

  // MCQ
  choiceType: null,

  // Written & MCQ
  confidenceRating: {
    5: [0, 0],
    4: [0, 0],
    3: [0, 0],
    2: [0, 0],
    1: [0, 0],
  },

  // MCQ & Likert
  choices: [],
})

export const choiceDefault: Required<Choice> = {
  id: '',
  text: '',
  displayIndex: 0,

  // MCQ
  correct: false,
  imageUrl: '',
  points: 0,

  // Likert
  value: null,
}

export const responseGradingDefault: Required<
  Unsaved<WrittenQuestionGradingCategory>
> = {
  testGradingCategoryId: '',
  levels: [],
  displayIndex: 0,
}

export const writtenResponseGradingCategoryLevelDefault: Required<WrittenQuestionGradingCategoryLevel> =
  {
    id: '',
    gradingStatements: [],
    description: '',
    requiredPoints: null,
    level: -1,
  }

export const getGradingBlockDefault = (): Required<
  WithoutMetaTimes<GradingBlock>
> => ({
  id: getNewId(),
  authoringStatus: AuthoringStatus.Draft,
  title: '',
  displayIndex: 0,
  gradeEntireSubmission: true,
  contentToGradeInstruction: '',
  outputFeedback: false,
  feedbackInstruction: '',
  originalContextFiles: [],
  markdownContextFiles: [],
  contextFileContext: null,
  gradingGuidelines: null,
  feedbackExample: null,
  memoAnswer: null,
  categories: [getGradingCategoryDefault()],
  description: null,
})
