import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import { defu } from 'defu'
import { useWorkspaceStore } from '~/stores/workspace'
import { assessmentDefault } from '~/static/data/assessments/assessmentDefaults'
import { type Assessment } from '~/types/Assessment'
import { StateError } from '~/errors/StateError'
import { NotFoundError } from '~/errors/NotFoundError'
import { AuthoringStatus } from '~/types/AuthoringStatus'
import { useTestStore } from '~/stores/test'
import { useUserStore } from '~/stores/user'
import { useWorkspaceUpdatesStore } from '~/stores/WorkspaceUpdates'
import {
  SseMessageType,
  type AssessmentLockUpdateMessage,
} from '~/types/SseMessage'

export const useAssessmentStore = defineStore('assessment', () => {
  const AssessmentService = assessmentService()
  const { addToast } = useToastNotifications()
  const { showDialog } = useDialogService()

  // OTHER STORES ========================================
  const workspaceStore = useWorkspaceStore()
  const testStore = useTestStore()
  const userStore = useUserStore()
  const workspaceUpdatesStore = useWorkspaceUpdatesStore()

  // STATE ==============================================
  const assessments = ref<Assessment[]>([])

  const assessmentCount = computed<number>(() => assessments.value.length)

  const publishedAssessmentCount = computed(() => {
    return assessments.value.filter((assessment) => assessment.isPublished)
      .length
  })

  const unpublishedAssessmentCount = computed(() => {
    return assessments.value.filter(
      (assessment) =>
        !assessment.isPublished &&
        assessment.authoringStatus !== AuthoringStatus.Draft,
    ).length
  })

  const draftAssessmentCount = computed(() => {
    return assessments.value.filter(
      (assessment) => assessment.authoringStatus === AuthoringStatus.Draft,
    ).length
  })

  const latestUpdatedAtTimestamp = useLatestUpdatedAtTimestamp(assessments)

  // CRUD ========================================
  function getAssessmentById(assessmentId: string) {
    const response = assessments.value.find(
      (assessment) => assessment.id === assessmentId,
    )

    if (!response) {
      Sentry.captureException(
        new NotFoundError(`Assessment with ID ${assessmentId} not found`),
      )
    }

    return response
  }

  async function createAssessment(assessment: Partial<Assessment>) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        'Error creating assessment. No workspace defined.',
      )

      Sentry.captureException(error)

      return { data: null, error }
    }

    const { data, error } = await AssessmentService.postAssessment(
      workspaceStore.currentWorkspaceId,
      defu(
        { primaryWorkspaceId: workspaceStore.currentWorkspaceId },
        assessment,
        assessmentDefault,
      ),
    )

    if (error !== null) {
      return { data, error }
    }

    assessments.value.push(data)

    return { data, error }
  }

  async function updateAssessmentById(
    assessmentId: string,
    assessment: Partial<Assessment>,
  ) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        `The assessment with ID ${assessmentId} could not be updated because no workspace was defined.`,
      )

      Sentry.captureException(error)

      return { data: null, error }
    }

    const { data, error } = await AssessmentService.updateAssessmentById(
      workspaceStore.currentWorkspaceId,
      assessmentId,
      assessment,
    )

    if (error != null) {
      return { data, error }
    }

    const index = assessments.value.findIndex(
      (assessment) => assessment.id === assessmentId,
    )
    assessments.value[index] = { ...assessments.value[index], ...data }

    return { data, error }
  }

  async function deleteAssessmentById(assessmentId: string) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        `The assessment with ID ${assessmentId} could not be deleted because no workspace was defined.`,
      )

      Sentry.captureException(error)

      return { error }
    }

    const { error } = await AssessmentService.deleteAssessmentById(
      workspaceStore.currentWorkspaceId,
      assessmentId,
    )

    if (error !== null) {
      return { error }
    }

    assessments.value = assessments.value.filter(
      (assessment) => assessment.id !== assessmentId,
    )

    return { error }
  }

  function getAssessmentsByTestId(testId: string) {
    return assessments.value.filter((assessment) =>
      assessment.tests.map((t) => t.testId).includes(testId),
    )
  }

  function getAssessmentsBySimulationId(simulationId: string) {
    const connectedTestIds = testStore
      .getTestsBySimulationId(simulationId)
      .map((t) => t.id)

    const connectedAssessments = assessments.value.filter((assessment) =>
      assessment.tests.some((t) => connectedTestIds.includes(t.testId)),
    )

    return connectedAssessments
  }

  // API CALLS ========================================
  async function fetchAssessmentsFromServer(workspaceId: string) {
    if (!workspaceId) return

    // Fetch assessments from the API
    const { data, error } =
      await AssessmentService.getWorkspaceAssessments(workspaceId)

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Failed to fetch assessments',
        message: 'There was an issue fetching assessments.',
        life: 5000,
      })
      return
    }

    assessments.value = data.local
  }

  async function fetchAssessmentUpdatesFromServer(workspaceId: string) {
    if (!workspaceId) return

    if (!latestUpdatedAtTimestamp.value) {
      Sentry.captureException(
        new StateError(
          'No latest updated at timestamp found. This should not happen.',
        ),
      )
      return
    }

    const { data, error } =
      await AssessmentService.getWorkspaceAssessmentUpdates(
        workspaceId,
        latestUpdatedAtTimestamp.value,
      )

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Error refreshing assessments',
        message:
          'Assessments could not be refreshed. If the problem persists, please try reloading the page.',
        life: 5000,
      })
      return
    }

    assessments.value = upsertArrayById(assessments.value, data.local)
  }

  async function lockAssessment(assessmentId: string) {
    if (!userStore.user?.id) {
      captureError(
        new StateError(
          `Attempted to lock assessment ${assessmentId} but no user id was found.`,
        ),
      )

      addToast({
        type: 'error',
        heading: 'Error locking assessment',
        message: 'There was an issue locking the assessment.',
        life: 5000,
      })

      return
    }

    const { data, error } = await AssessmentService.lockAssessment(
      assessmentId,
      userStore.user.id,
    )

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Error locking assessment',
        message: 'There was an issue locking the assessment.',
        life: 5000,
      })

      return
    }

    const index = assessments.value.findIndex(
      (assessment) => assessment.id === assessmentId,
    )
    assessments.value[index].lockedBy = data.lockedBy
    assessments.value[index].lockedAt = data.lockedAt
  }

  async function unlockAssessment(assessmentId: string) {
    const shouldUnlock = await showDialog<boolean>({
      type: 'confirm',
      props: {
        heading: 'Unlock Assessment',
        message:
          'Are you sure you want to unlock this assessment? Doing so will invalidate any existing assessment grades.',
        confirmButtonLabel: 'Unlock Assessment',
        cancelButtonLabel: 'Cancel',
      },
    })

    if (!shouldUnlock) return shouldUnlock ?? false

    const { error } = await AssessmentService.unlockAssessment(assessmentId)

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Error unlocking assessment',
        message: 'There was an issue unlocking the assessment.',
        life: 5000,
      })

      return false
    }

    const index = assessments.value.findIndex(
      (assessment) => assessment.id === assessmentId,
    )

    if (index === -1) return false

    assessments.value[index].lockedBy = null
    assessments.value[index].lockedAt = null

    addToast({
      type: 'default',
      heading: 'Assessment unlocked',
      message: `The assessment '${assessments.value[index].title}' has been unlocked.`,
      life: 5000,
    })

    return true
  }

  function handleAssessmentLockUpdate(data: AssessmentLockUpdateMessage) {
    const index = assessments.value.findIndex(
      (assessment) => assessment.id === data.id,
    )

    if (index === -1) return

    assessments.value[index].lockedBy = data.data.lockedBy ?? null
    assessments.value[index].lockedAt = data.data.lockedAt ?? null
  }

  function reset() {
    assessments.value = []

    workspaceUpdatesStore.unsubscribe(
      SseMessageType.ASSESSMENT_LOCK_UPDATE,
      handleAssessmentLockUpdate,
    )
  }

  const {
    $isInitialised,
    $isInitialising,
    $isUpdating,
    $initialise,
    $ensureInitialised,
    $reset,
    $refresh,
  } = useStoreManager(
    computed(() => workspaceStore.currentWorkspaceId),

    async () => {
      await workspaceStore.$ensureInitialised()

      reset()

      if (!workspaceStore.currentWorkspaceId) return false

      await fetchAssessmentsFromServer(workspaceStore.currentWorkspaceId)

      workspaceUpdatesStore.subscribe(
        SseMessageType.ASSESSMENT_LOCK_UPDATE,
        handleAssessmentLockUpdate,
      )

      return true
    },

    reset,

    async () => {
      if (!workspaceStore.currentWorkspaceId) return

      await fetchAssessmentUpdatesFromServer(workspaceStore.currentWorkspaceId)
    },
  )

  return {
    $reset,
    $refresh,
    $ensureInitialised,
    $initialise,
    $isInitialising,
    $isInitialised,
    $isUpdating,
    assessments,
    assessmentCount,
    publishedAssessmentCount,
    unpublishedAssessmentCount,
    draftAssessmentCount,
    getAssessmentById,
    updateAssessmentById,
    createAssessment,
    deleteAssessmentById,
    getAssessmentsByTestId,
    getAssessmentsBySimulationId,
    lockAssessment,
    unlockAssessment,
  }
})
