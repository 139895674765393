import { defineStore, acceptHMRUpdate } from 'pinia'
import { useWorkspaceStore } from '~/stores/workspace'
import { useUserStore } from '~/stores/user'
import { SseMessageType } from '~/types/SseMessage'

export const useWorkspaceUpdatesStore = defineStore('workspaceUpdates', () => {
  // OTHER STORES ========================================================================
  const workspaceStore = useWorkspaceStore()
  const userStore = useUserStore()

  // EVENTS/PUBSUB ========================================================================
  const topics = ref({}) as Ref<Record<SseMessageType, Function[]>>

  function subscribe(topic: SseMessageType, callback: Function) {
    if (!topics.value[topic]) topics.value[topic] = []

    topics.value[topic].push(callback)
  }

  function unsubscribe(topic: SseMessageType, callback: Function) {
    if (!topics.value[topic]) return

    topics.value[topic] = topics.value[topic].filter((cb) => cb !== callback)

    if (topics.value[topic].length === 0) {
      delete topics.value[topic]
    }
  }

  function publish(topic: SseMessageType, data: any) {
    if (!topics.value[topic]) return

    topics.value[topic].forEach((callback) => {
      try {
        callback(data)
      } catch (e) {
        // TODO Sentry
        console.error('Error calling callback:', e)
      }
    })
  }

  // SSE STATE ========================================================================
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const sseUrl = computed(() => {
    if (!workspaceStore.currentWorkspace?.id) return undefined

    if (!userStore.user?.id) return undefined

    if (!serverUrl) return undefined

    if (Object.keys(topics.value).length === 0) return undefined

    return `${serverUrl}/workspaces-sse/${workspaceStore.currentWorkspace.id}/users/${userStore.user.id}`
  })

  // HANDLERS ==========================================================================

  function handleData(data: string | null) {
    if (!data) return

    let parsedData: any

    try {
      parsedData = JSON.parse(data)
    } catch (e) {
      console.error('Error parsing data:', e)
      return
    }

    // TODO Remove
    console.info('SSE message receievd and parsed:', parsedData)

    if (!parsedData.eventType) {
      console.warn('Tried to publish data, but no topic (type) was provided')
      return
    }

    publish(parsedData.eventType, parsedData)
  }

  // EVENT SOURCE & WATCHER ============================================================

  const { data, error, status, eventSource } = useEventSource(sseUrl)

  watch(
    eventSource,
    () => {
      if (!eventSource.value) return

      eventSource.value.onmessage = (event: any) => handleData(event.data)
    },
    { immediate: true },
  )

  return {
    subscribe,
    unsubscribe,
    publish,
    topics,
    data,
    error,
    status,
    eventSource,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWorkspaceUpdatesStore, import.meta.hot),
  )
}
