import { httpService } from '~/services/httpService'
import type { ShareableEntityList } from '~/types/ShareableEntityList'
import type { Assessment } from '~/types/Assessment'

export function assessmentService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const { getResource, deleteResource, postResource, patchResource } =
    httpService<Assessment>(serverUrl)

  async function getAssessments(queryParameters: Record<string, string> = {}) {
    const response = await getResource<Assessment[]>('/assessments', {
      query: queryParameters,
    })
    return response
  }

  async function getAssessmentById(assessmentId: string) {
    const response = await getResource(`assessments/${assessmentId}`)
    return response
  }

  async function getWorkspaceAssessments(workspaceId: string) {
    const response = await getResource<ShareableEntityList<Assessment>>(
      `/workspaces/${workspaceId}/assessments`,
    )
    return response
  }

  async function postAssessment(
    workspaceId: string,
    body: Partial<Assessment>,
  ) {
    const response = await postResource(
      `/workspaces/${workspaceId}/assessments`,
      body,
    )
    return response
  }

  async function updateAssessmentById(
    workspaceId: string,
    assessmentId: string,
    body: Partial<Assessment>,
  ) {
    const response = await patchResource(
      `/workspaces/${workspaceId}/assessments/${assessmentId}`,
      body,
    )
    return response
  }

  async function deleteAssessmentById(
    workspaceId: string,
    assessmentId: string,
  ) {
    const response = await deleteResource(
      `/workspaces/${workspaceId}/assessments/${assessmentId}`,
    )
    return response
  }

  async function getWorkspaceAssessmentUpdates(
    workspaceId: string,
    updatedAtGt: string,
  ) {
    const response = await getResource<ShareableEntityList<Assessment>>(
      `/workspaces/${workspaceId}/assessments`,
      {
        query: {
          updatedAtGt,
        },
      },
    )
    return response
  }

  async function lockAssessment(assessmentId: string, lockedBy: string) {
    const response = await postResource<
      { lockedBy: string; lockedAt: string },
      { lockedBy: string }
    >(`/assessments/${assessmentId}/lock`, { lockedBy })
    return response
  }

  async function unlockAssessment(assessmentId: string) {
    const response = await postResource<string, undefined>(
      `/assessments/${assessmentId}/unlock`,
      undefined,
    )
    return response
  }

  return {
    getAssessments,
    getAssessmentById,
    getWorkspaceAssessments,
    postAssessment,
    updateAssessmentById,
    deleteAssessmentById,
    getWorkspaceAssessmentUpdates,
    lockAssessment,
    unlockAssessment,
  }
}
