export default {
  root: {
    class: [
      'relative',

      // Flexbox & Alignment
      'inline-flex',
      'align-bottom',

      // Size
      'w-4 h-4',

      // Misc
      'cursor-pointer',
      'select-none',
    ],
  },
  box: ({ props }) => {
    return {
      class: [
        // Flexbox
        'flex justify-center items-center',

        // Size
        'w-4 h-4',

        // Shape
        'border',
        'rounded-[4px]',
        'shadow-sm',
        'overflow-hidden',

        // Transition
        'transition duration-200 ease-in-out',

        // Colours for datatable
        {
          'bg-white border-surface-200 peer-hover:bg-primary-50 peer-hover:border-primary-600':
            !props.modelValue && !props.value,
          'bg-primary-600 border-primary-600 peer-hover:bg-primary-700 peer-hover:border-primary-700':
            props.modelValue && !props.value,
        },

        // Colors
        {
          'text-surface-700 dark:text-white/80':
            props.value !== props.modelValue && props.value !== undefined,
          'bg-surface-0 dark:bg-surface-900':
            props.value !== props.modelValue && props.value !== undefined,
          'border-surface-200 dark:border-surface-700':
            props.value !== props.modelValue && props.value !== undefined,
          'border-primary-600 dark:border-primary-400':
            props.value === props.modelValue && props.value !== undefined,
          'bg-primary-600 dark:bg-primary-400':
            props.value === props.modelValue && props.value !== undefined,
        },

        // States
        {
          'peer-hover:border-primary-600 peer-hover:bg-primary-50 dark:peer-hover:border-primary-400':
            !props.disabled &&
            props.value !== props.modelValue &&
            props.value !== undefined,
          'peer-hover:border-primary-700 dark:peer-hover:border-primary-300 peer-hover:bg-primary-700 dark:peer-hover:bg-primary-300':
            !props.disabled &&
            props.value === props.modelValue &&
            props.value !== undefined,
          'peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20':
            !props.disabled,
          'opacity-60 cursor-default': props.disabled,
        },
      ],
    }
  },
  input: {
    class: [
      'peer',

      // Size
      'w-full',
      'h-full',

      // Position
      'absolute',
      'top-0 left-0',
      'z-10',

      // Spacing
      'p-0',
      'm-0',

      // Shape
      'opacity-0',
      'rounded-md',
      'outline-none',
      'border-2 border-surface-200 dark:border-surface-700',

      // Misc
      'appareance-none',
      'cursor-pointer',
    ],
  },
  icon: ({ props }) => ({
    class: [
      'block',

      // Shape
      'rounded-full',

      // Size
      'w-[0.857rem] h-[0.857rem]',

      // Colors
      'bg-surface-0 dark:bg-surface-900',

      // Conditions
      {
        'backface-hidden scale-10 invisible': props.value !== props.modelValue,
        'transform visible scale-[1.1]': props.value === props.modelValue,
      },

      // Transition
      'transition duration-200',
    ],
  }),
}
