import type { GlobalEntityData } from './GlobalEntityData'
import type { NumberOrNull } from '~/types/NumberOrNull'
import type { WithAuthoringStatus } from '~/types/AuthoringStatus'
import type { DatabaseMetadata } from '~/types/DatabaseMetadata'
import type { WithDisplayColor } from '~/types/Colors'
import type { WithDisplayIndex } from '~/types/DisplayIndex'

export enum CompletionTrigger {
  OnCandidateMarkComplete = 'on-candidate-mark-complete', // At the end of an assessment, user clicks 'finish assessment'
  OnAllTestsComplete = 'on-all-tests-complete', // Automatically triggered when all tests are complete
  OnVerboseTrigger = 'on-verbose-trigger', // Triggered by external system (eg Traverse) when user marks challenge complete
}

export enum GradingType {
  Skills = 'skills',
  Competency = 'competency',
  Preference = 'preference',
  Knowledge = 'knowledge',
}

export enum CategoryScoreAllocation {
  ActualUserScore = 'actual-user-score',
  CategoryLevelScore = 'category-level-score',
}

export interface AssessmentTestConnection {
  testId: string // Test.id
  isRequired: boolean
}

export interface CategoryTestConnection {
  testId: string // Test.id
  weighting: NumberOrNull // 1 - 100
  useOverallTestScore: boolean // Instead of specified test categories
  categories: string[] // TestGradingCategory.id[]
}

export interface AssessmentGradingCategoryLevel extends WithAuthoringStatus {
  level: NumberOrNull // or string?
  requiredPercentage: NumberOrNull // this becomes the user's entry
  requiredPoints: NumberOrNull // this becomes an FE-side calc whenever the above is updated: = pointsAvailableFromQuestions * (requiredPercentage / 100)
  title: string
  description: string
}

export enum TestScoreWeighting {
  Manual = 'manual',
  Points = 'points',
}

export interface AssessmentGradingCategory
  extends WithAuthoringStatus,
    WithDisplayColor,
    WithDisplayIndex {
  id: string
  title: string
  testConnections: CategoryTestConnection[]
  testScoreWeighting: TestScoreWeighting | null
  levels: AssessmentGradingCategoryLevel[]
  maxAvailablePoints: number
  weighting: number | null
}

export enum CategoryScoreWeighting {
  Manual = 'manual',
  Points = 'points',
}

// export interface Grading {
//   type: GradingType | null
//   rubric: AssessmentGradingCategory[]
//   useNumericalScoring: boolean // no idea what this does from a business logic perspective
//   categoryScoreAllocation: CategoryScoreAllocation | null
//   categoryScoreWeighting: CategoryScoreWeighting | null
//   maxAvailablePoints: number
// }

export interface Assessment
  extends DatabaseMetadata,
    WithAuthoringStatus,
    GlobalEntityData {
  id: string
  title: string
  isPublished: boolean
  objective: string
  completionTrigger: CompletionTrigger | null
  tests: AssessmentTestConnection[]
  hash: string // for accessing via url `/assessment/[hash]`
  requiresEmailConfirmation: boolean
  requiresIntegrityPledge: boolean

  gradingType: GradingType | null
  rubric: AssessmentGradingCategory[]
  useNumericalScoring: boolean // no idea what this does from a business logic perspective
  categoryScoreAllocation: CategoryScoreAllocation | null
  categoryScoreWeighting: CategoryScoreWeighting | null
  maxAvailablePoints: number

  lockedAt: string | null
  lockedBy: string | null
  autoGrade: boolean
}
