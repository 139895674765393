<template>
  <Dropdown
    v-model="selectedValue"
    :options="options"
    :option-label="typeof options[0] === 'object' ? optionLabel : undefined"
    :option-value="typeof options[0] === 'object' ? optionValue : undefined"
    :placeholder="placeholder"
    :show-clear="clearable"
  >
    <template #value>
      <span class="flex items-center gap-2">
        <AppIcon
          v-if="icon"
          :icon="icon"
          class="shrink-0 text-base"
        />
        <span class="grow">{{ selectedLabelOrPlaceholder }}</span>
      </span>
    </template>
    <template
      v-if="useIndicatorDots"
      #option="{ option }"
    >
      <span class="flex items-center gap-2">
        <AppIndicatorDot
          :class="option.color ?? 'text-current'"
          class="bg-current"
        />
        <span>{{ option.label }}</span>
      </span>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import Dropdown from 'primevue/dropdown'

const props = defineProps({
  modelValue: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  options: {
    type: Array as PropType<
      string[] | { value: string | null | undefined; label: string }[]
    >,
    required: true,
    default: () => [],
  },
  optionLabel: {
    type: String,
    required: false,
    default: 'label',
  },
  optionValue: {
    type: String,
    required: false,
    default: 'value',
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  clearable: {
    type: Boolean,
    required: false,
    default: false,
  },
  useIndicatorDots: {
    type: Boolean,
    required: false,
    default: false,
  },
  icon: {
    type: String,
    required: false,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])

const selectedValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const selectedLabelOrPlaceholder = computed(() => {
  if (selectedValue.value === null) return props.placeholder

  if (props.options.length === 0) return props.placeholder

  if (typeof props.options[0] !== 'object') {
    return selectedValue.value
  }

  const selectedOption = props.options.find((option: any) => {
    if (typeof option === 'object' && props.optionValue in option) {
      return option[props.optionValue] === selectedValue.value
    }

    return false
  })

  if (!selectedOption) return props.placeholder

  if (typeof selectedOption === 'object') {
    return selectedOption[props.optionLabel as keyof typeof selectedOption]
  }

  return null
})
</script>
