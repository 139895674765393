export function useUrlBuilder(
  baseUrl: string,
  queryParams: Ref<Record<string, string | string[] | null | undefined>>,
  options: { arrayStrategy: 'join' | 'append' } = { arrayStrategy: 'join' },
) {
  const { arrayStrategy } = options

  const hasQueryParams = computed(() => {
    return Object.values(queryParams.value).some(
      (value) => value !== null && value !== undefined && value.length > 0,
    )
  })

  const fullUrl = computed(() => {
    let output = baseUrl.trim().replace(/\/$/, '')

    if (!hasQueryParams.value) return output

    output += '?'

    Object.entries(queryParams.value).forEach(([key, value]) => {
      if (value === null || value === undefined) return

      if (Array.isArray(value)) {
        if (value.length === 0) return

        value = value.map((v) => encodeURIComponent(v))

        if (arrayStrategy === 'join') {
          output += `${key}=${value.join(',')}&`
        } else {
          value.forEach((v) => {
            output += `${key}=${v}&`
          })
        }
      } else {
        value = encodeURIComponent(value)
        output += `${key}=${value}&`
      }
    })

    return output.replace(/&$/, '')
  })

  return fullUrl
}
