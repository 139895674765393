<template>
  <component
    :is="component"
    v-if="dialog"
    v-model="dialog.isOpen"
    v-bind="dialog.props"
    @resolve="(data: any) => handleResolve(data)"
    @close="handleClose"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  dialogId: {
    type: String,
    required: true,
  },
})

const { getDialogById, resolveDialog } = useDialogService()

const DialogDelete = resolveComponent('DialogDelete')
const DialogAuthExpired = resolveComponent('DialogAuthExpired')
const DialogSimulationDraft = resolveComponent('DialogSimulationDraft')
const DialogTestDraft = resolveComponent('DialogTestDraft')
const DialogAssessmentDraft = resolveComponent('DialogAssessmentDraft')
const DialogInputText = resolveComponent('DialogInputText')
const DialogReadOnlyCopy = resolveComponent('DialogReadOnlyCopy')
const DialogLeaveWithoutSaving = resolveComponent('DialogLeaveWithoutSaving')
const DialogManualCandidateData = resolveComponent('DialogManualCandidateData')
const DialogTextView = resolveComponent('DialogTextView')
const DialogConfirm = resolveComponent('DialogConfirm')
const DialogSimulationLink = resolveComponent('DialogSimulationLink')
const DialogTestLink = resolveComponent('DialogTestLink')
const DialogFileSubmissionUpload = resolveComponent(
  'DialogFileSubmissionUpload',
)
const DialogSubmissionGroupSelect = resolveComponent(
  'DialogSubmissionGroupSelect',
)

const dialog = computed(() => {
  return getDialogById(props.dialogId) ?? null
})

const component = computed(() => {
  if (!dialog.value) {
    return 'div'
  }

  switch (dialog.value.type) {
    case 'delete':
      return DialogDelete
    case 'auth-expired':
      return DialogAuthExpired
    case 'simulation-draft':
      return DialogSimulationDraft
    case 'test-draft':
      return DialogTestDraft
    case 'assessment-draft':
      return DialogAssessmentDraft
    case 'input-text':
      return DialogInputText
    case 'read-only-copy':
      return DialogReadOnlyCopy
    case 'leave-without-saving':
      return DialogLeaveWithoutSaving
    case 'manual-candidate-data':
      return DialogManualCandidateData
    case 'text-view':
      return DialogTextView
    case 'confirm':
      return DialogConfirm
    case 'simulation-link':
      return DialogSimulationLink
    case 'test-link':
      return DialogTestLink
    case 'file-submission-upload':
      return DialogFileSubmissionUpload
    case 'submission-group-select':
      return DialogSubmissionGroupSelect
    default:
      return 'div'
  }
})

function handleResolve(data: any) {
  resolveDialog(props.dialogId, data)
}

function handleClose() {
  resolveDialog(props.dialogId, null)
}
</script>
