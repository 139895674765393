<template>
  <AppDialog
    v-model="isDialogVisible"
    :header="`Upload ${fileCount} file${fileCount === 1 ? '' : 's'}`"
    @click:close="emit('close')"
  >
    <div class="flex flex-col gap-3">
      <h3 class="caption-subheading">Select group(s)</h3>
      <ul class="flex flex-col">
        <li
          v-for="group in submissionGroups"
          :key="group.id"
          class="h-12 flex justify-start gap-2 items-center px-2 border-b last-of-type:border-b-0 duration-200 cursor-pointer"
          :class="{
            'bg-white hover:bg-surface-50': !selectedGroupIds.includes(
              group.id,
            ),
            'bg-surface-100': selectedGroupIds.includes(group.id),
          }"
          @click="handleGroupSelection(group.id)"
        >
          <div
            class="size-4 border rounded-[4px] cursor-pointer flex justify-center items-center"
            :class="{
              'bg-primary-500 border-primary-500 hover:bg-primary-600':
                selectedGroupIds.includes(group.id),
              'bg-white border-surface-200 hover:bg-surface-100':
                !selectedGroupIds.includes(group.id),
            }"
          >
            <AppIcon
              v-if="selectedGroupIds.includes(group.id)"
              icon="fa-solid fa-check"
              class="text-white text-xs"
            />
          </div>
          <span class="text-xs text-surface-800 font-medium">{{
            group.name
          }}</span>
        </li>
      </ul>
    </div>
    <template #actions>
      <AppButtonSecondary
        label="Cancel"
        @click="$emit('resolve', false)"
      />
      <AppButtonPrimary
        label="Upload"
        autofocus
        @click="handleUpload"
      />
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
import type { SubmissionGroup } from '~/types/SubmissionGroup'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  fileCount: {
    type: Number,
    required: true,
  },
  submissionGroups: {
    type: Array as PropType<SubmissionGroup[]>,
    required: true,
  },
})

const isDialogVisible = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

const selectedGroupIds = ref<string[]>([])

function handleGroupSelection(groupId: string) {
  if (selectedGroupIds.value.includes(groupId)) {
    selectedGroupIds.value = selectedGroupIds.value.filter(
      (id) => id !== groupId,
    )
  } else {
    selectedGroupIds.value.push(groupId)
  }
}

function handleUpload() {
  emit('resolve', selectedGroupIds.value)
}
</script>
